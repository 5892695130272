import { withErrorBoundary } from 'shared'
import { Fragment } from "react";
import QRCode from "qrcode.react";;


class Qrcode extends Component {

  constructor(props) {
    super(props);

    this.state = {
      timeLeft: '',
      code :'',
      suspended: false,
      hasCard: true
    };
  }

  componentDidMount() {
    let params = new URLSearchParams(this.props.location.search);
    let code = params.get('code')
    let is_suspended = params.get('suspended') == 'true' ? true : false ;
    let card = params.get('has_card') == 'true' ? true : false

    if (code){
      this.setState({ code: code, suspended: is_suspended, hasCard: card })
    }
    else{
      this.fetchQRCode();
    }
    const { suspended, hasCard } = this.state;

    if (!suspended && hasCard){
      this.intervalId = setInterval(() => {
        this.setState({ timeLeft: this.calculateTimeLeft() });
      }, 1000);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.timeLeft === 0 && prevState.timeLeft !== 0) {
      this.fetchQRCode();
    }
  }

  componentWillUnmount() {
    let params = new URLSearchParams(this.props.location.search);
    console.log("In Will Mount", params)
    clearInterval(this.intervalId);
  }

  async fetchQRCode() {
    const { timeLeft, code } = this.state
    let id =  User.current.isGuestExperience ? code :  User.current.id
    let data = await Backend.get(`/users/${id}/scan_key_code`)
    this.setState(data);
  }

  calculateTimeLeft() {
    const { code } = this.state;
    let expirationTime = parseInt(code.slice(code.length - 10)) + 90
    let currentTime = Math.floor(new Date() / 1000)
    const secondsLeft = expirationTime - currentTime;
    return secondsLeft > 0 ? secondsLeft : 0;
  }


  render() {
    const { timeLeft, code, suspended, hasCard } = this.state;

    const styles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '50vh',
    };
    if (suspended) {
      return <div style={styles}>
        <p className="text-center">We were unable to process your last order.  Please add a new credit card on file to your account to continue to use Big Pocono on the Go.  For questions, please call:  507-629-8546. </p>
      </div>
    }

    if (!hasCard && document.getElementById('square-js')) {
      return(
        <div style={styles}>
          <p className="text-center mt-5">
            Big Pocono on the Go utilizes a card-on-file payment method to complete your transaction upon exit of the store.
          </p>
          <p className="text-center">
            Please provide payment details by locating the Settings Menu at the top-right of your account. 
          </p>
          <p className="text-center">
            Upon completion, please retry the Enter Big Pocono on the Go button at the top of your screen. 
          </p>
        </div>
      )
    }

    if (!suspended && hasCard)
      return (
      <>
        <div style={styles}>
          <Fragment>
            <QRCode value={code} id="canvas" />
          </Fragment>
        </div>
        {
          timeLeft > 0 ?
            <div className="text-center">Expires in {timeLeft} seconds</div>
          :
            <div className="text-center">This QR code is expired</div>
        }
        <div className="text-center">For security reasons, this QR code will become invalid after 90 seconds.  A new QR code can be obtained by returning to the mobile app Home Feed to reactivate your QR code.</div>
      </>
    )
  }
}

export default withErrorBoundary(Qrcode)
