import { LogoImage } from 'customizations'
import { User } from 'api'
import { withErrorBoundary, Spinner } from 'shared'
import { SmartButtonStub, SmartButtonLink } from 'ui/smart-buttons'

class ChangePasswordPage extends Component
  state: { password: '', passwordConfirmation: '' }

  render: ->
      <div className="ChangePassword row justify-content-center">
        <div className='col-md-6'>
          <h3 className="section-heading">Set Password</h3>

          <form>
            <div className="labeled-inline-field">
              <label>New Password</label>
              <input type="password" className="form-control"
                placeholder="****"
                autoComplete="new-password"
                name="password" value={this.state.password}
                onChange={this.inputChanged}
              />
            </div>
            <div className="labeled-inline-field">
              <label>New Password Confirmation</label>
              <input type="password" className="form-control"
                placeholder="****"
                autoComplete="new-password"
                name="passwordConfirmation" value={this.state.passwordConfirmation}
                onChange={@inputChanged} />
            </div>

            <input type="hidden" autoComplete="username" name="username" value={User.current.email} />
          </form>

          {
            @state.error &&
            <p className="alert alert-danger text-center">{this.state.error}</p>
          }

          <div className="text-center">
            <SmartButtonStub text="Change Password" buttonType="button" className="my-2 admin-purple px-5 inverted primary" click={@changePassword} />
          </div>
        </div>
      </div>

  changePassword: =>
    { password, passwordConfirmation } = @state
    if _.blank(password)
      @setState error: 'Please enter a new password!'
    else if password isnt passwordConfirmation
      @setState error: 'Passwords do not match!'
    else
      try
        res = await Backend.put('/profile/password', { password })
        console.info('password change result', res)
        User.resetPasswordChangeFlag()
        @setState error: null
        window.location = '/app'
      catch xhr
        if xhr.status >= 400 && xhr.status < 500
          @setState error: xhr.responseJSON.message

  cancel: =>
    User.resetPasswordChangeFlag()
    App.go('home')

  inputChanged: (e) =>
    { name, value } = e.target
    @setState "#{name}": value, error: null

export default withErrorBoundary(ChangePasswordPage)
